import React from "react";
import { Row, Col, Typography } from "antd";
import { InstagramOutlined } from "@ant-design/icons";

import { Devices, DefaultProps } from "../types";

const { Title } = Typography;

const style = {
    mobile: {
        span: 24,
        offset: 0,
        row: {
            // backgroundColor: "#7d7d7d", //"#545454",
            backgroundColor: "#545454",
            color: "white",
            minHeight: "200px",
            padding: "25px",
        },

        input: {
            span: 24,
            offset: 0,
        },
    },
    web: {
        span: 4,
        offset: 8,
        row: {
            // backgroundColor: "#7d7d7d", //"#545454",
            backgroundColor: "#545454",
            color: "white",
            minHeight: "200px",
            padding: "25px",
        },
        input: {
            span: 18,
            offset: 3,
        },
    },
};

const content = {
    en: {},
    cz: {},
};

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};

function Footer(props: DefaultProps) {
    const { language, device } = props;

    const _style = device === Devices.web ? style.web : style.mobile;

    return (
        <Row style={_style.row}>
            <Col span={_style.span} offset={_style.offset}>
                <br />
                <Row>
                    <Col span={24}>
                        <Title style={{ color: "white" }} level={4}>
                            Social Media
                        </Title>
                        <a
                            style={{ color: "white" }}
                            href="https://www.instagram.com/vanco_co/"
                        >
                            <InstagramOutlined /> @vanco_co{" "}
                        </a>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default Footer;
