import React from "react";

import VancoLogo from "../assets/logos/logo.svg";
import { Row, Typography } from "antd";

import { Languages, DefaultProps } from "../types";

const { Title } = Typography;

const style = {
    div: {
        height: "60vh",
    },
    row: {
        width: "100%",
        height: "50%",
        padding: "5px",
    },
    logo: {
        height: "5vh",
    },
};

const content = {
    cz: {
        comingSoon: "již brzy",
    },
    en: {
        comingSoon: "coming soon",
    },
};

function ComingSoon(props: DefaultProps) {
    const { language } = props;

    const _language = language === Languages.en ? content.en : content.cz;

    return (
        <div style={style.div}>
            <Row align="bottom" justify="center" style={style.row}>
                <img style={style.logo} src={VancoLogo} />
            </Row>
            <Row justify="center" style={style.row}>
                <Title> {_language.comingSoon} </Title>
            </Row>
        </div>
    );
}

export default ComingSoon;
