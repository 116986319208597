import React, { useState } from "react";
import _ from "lodash";
import { Form, Row, Col, Input, Typography } from "antd";
import { PlusOutlined, CheckOutlined } from "@ant-design/icons";

import { Devices, Languages, DefaultProps } from "../types";

const { Search } = Input;
const { Title } = Typography;

const style = {
    general: {
        row: {
            height: "40vh",
            minHeight: "219px",
        },
    },
    mobile: {
        span: 24,
        offset: 0,
        row: {
            padding: "5px",
        },
        input: {
            span: 24,
            offset: 0,
        },
    },
    web: {
        span: 8,
        offset: 8,
        input: {
            span: 18,
            offset: 3,
        },
    },
};

const content = {
    en: {
        input: "Your email address",
        description:
            "Sign up to our email newsletter and stay up to date with the new releases. You can unsubscribe at any time.",
    },
    cz: {
        input: "Your email address",
        description: "Přihlaste se k odebírání našeho newsletteru",
    },
};

const validateMessages = {
    types: {
        email:
            "The email you provided is not valid, please make sure you have not made any typos!",
    },
};

function NewsLetterSignUp(props: DefaultProps) {
    const { language, device } = props;
    const _content = language === Languages.en ? content.en : content.cz;
    const _style = _.merge(
        style.general,
        device === Devices.web ? style.web : style.mobile
    );

    const [form] = Form.useForm();
    const [emailStatus, setEmailStatus] = useState({
        loading: false,
        loaded: false,
    });

    function submit(values: any) {
        setEmailStatus({
            loading: true,
            loaded: false,
        });

        fetch(
            "https://us-central1-vanco-d5c89.cloudfunctions.net/subscribe",
            //fetch("https://us-tral1-vanco-d5c89.cloudfunctions.net/subscribe",
            {
                mode: "no-cors",
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: values.email }),
            }
        )
            .then(() => {
                setEmailStatus({
                    loading: false,
                    loaded: true,
                });
            })
            .catch((err) => {
                setEmailStatus({
                    loading: false,
                    loaded: false,
                });
            });
    }

    return (
        <Row style={_style.row}>
            <Col span={_style.span} offset={_style.offset}>
                <Row>
                    <Col span={24}>
                        <Title level={2}>Newsletter</Title>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>{_content.description}</Col>
                </Row>
                <br />
                <Row>
                    <Col span={_style.input.span} offset={_style.input.offset}>
                        <Form
                            form={form}
                            onFinish={submit}
                            validateMessages={validateMessages}
                        >
                            <Form.Item>
                                <Form.Item
                                    name="email"
                                    rules={[{ type: "email" }]}
                                    noStyle
                                >
                                    <Search
                                        placeholder={_content.input}
                                        enterButton={
                                            emailStatus.loaded ? (
                                                <CheckOutlined />
                                            ) : (
                                                <PlusOutlined />
                                            )
                                        }
                                        size="middle"
                                        onSearch={form.submit}
                                        loading={emailStatus.loading}
                                    />
                                </Form.Item>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <br />
            </Col>
        </Row>
    );
}

export default NewsLetterSignUp;
