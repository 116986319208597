import React from "react";

import ComingSoon from "./ComingSoon";
import NewsLetterSignUp from "./NewsLetterSignUp";
import Footer from "./Footer";

import { DefaultProps } from "../types";

function HomePage(props: DefaultProps) {
    return (
        <div>
            <ComingSoon {...props} />
            <NewsLetterSignUp {...props} />
            <Footer {...props} />
        </div>
    );
}

export default HomePage;
