export enum Devices {
    mobile,
    web,
}

export enum Languages {
    en,
    cz,
}

export interface DefaultProps {
    language: Languages;
    device: Devices;
}
