import React, { useLayoutEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.less";
import { mobileCheck } from "./version";

import { Devices, Languages } from "./types";

import HomePage from "./pages/HomePage";

let language = Languages.en;

function useWindowSize() {
    const [device, setDevice] = useState(
        mobileCheck() ? Devices.mobile : Devices.web
    );
    useLayoutEffect(() => {
        function updateDevice() {
            setDevice(mobileCheck() ? Devices.mobile : Devices.web);
        }
        window.addEventListener("resize", updateDevice);
        updateDevice();
        return () => window.removeEventListener("resize", updateDevice);
    }, []);
    return device;
}

function App() {
    const device = useWindowSize();

    return (
        <div className="App">
            <header className="App-header">
                <HomePage language={language} device={device} />
            </header>
        </div>
    );
}

export default App;
